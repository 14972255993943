import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./services.css"

const Services = () => {
  const cards = [
    {
      id: 1,
      title: "WEB-DESIGN",
      imageUrl: "https://github.com/PP10084/ligovis_assests/blob/main/innovaten.png?raw=true",
      content:
"At Ligovis, we provide innovative and professional web design services to create visually stunning and user-friendly websites that effectively represent your brand and engage your audience."
    },
    {
      id: 2,
      title: "WEB-DEVELOPMENT",
      imageUrl: "https://github.com/PP10084/ligovis_assests/blob/main/developn.png?raw=true",
      content:
        "Ligovis specializes in robust web development, creating dynamic and scalable solutions tailored to meet the unique needs of each client, ensuring seamless functionality and optimal performance.",
    },
    {
      id: 3,
      title: "APP-DEVELOPMENT",
      imageUrl: "https://github.com/PP10084/ligovis_assests/blob/main/mobile.png?raw=true",
      content:"At Ligovis, we offer expert app development services, creating innovative and user-friendly mobile applications to enhance your digital presence and streamline your business operations."
    },
    {
      id: 4,
      title: "WEB-PENTESTING",
      imageUrl: "https://github.com/PP10084/ligovis_assests/blob/main/wwwn.png?raw=true",
      content:"At Ligovis, we offer thorough and comprehensive web penetration testing services to uncover and mitigate security vulnerabilities, ensuring your website is robust and safeguarded against potential cyber threats."
    },
    {
      id: 5,
      title: "API-PENTESTING",
      imageUrl:"https://github.com/PP10084/ligovis_assests/blob/main/apin.png?raw=true",
      content:
"At Ligovis, we provide comprehensive API penetration testing services to identify and address security vulnerabilities, ensuring your APIs are robust and secure against potential threats."
    },
    {
      id: 6,
      title: "WEB-SECURITY",
      imageUrl: "https://github.com/PP10084/ligovis_assests/blob/main/cloudn.png?raw=true",
      content:
        "Ligovis prioritizes web security, implementing advanced measures to safeguard online assets, protect sensitive data, and mitigate cyber threats, providing clients with peace of mind and a secure digital environment.",
    },
    {
      id: 7,
      title: "DIGITAL-MARKETING",
      imageUrl: "https://github.com/PP10084/ligovis_assests/blob/main/digital.png?raw=true",
      content:"At Ligovis, we offer expert and reliable app development services, creating innovative and user-friendly mobile applications to enhance your digital presence and streamline your business operations."
    },


  ];
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Set to 1 to display one card at a time in mobile view
        },
      },
    ],
  };

  return (
    <div
      id="services"
      className="h-auto flex flex-col items-center justify-center py-8"
      style={
        {
          // background: `linear-gradient(rgb(112, 93, 239 , 0.7), rgb(112, 93, 239, 0.7)) `,
        }
      }
    >
      <h1 className="text-5xl font-bold text-white mb-8">SERVICES</h1>
      <div className="w-full" style={{ height: "480px" }} ref={sliderRef}>
        <Slider {...settings}>
          {cards.map((card) => (
            <div key={card.id} className="px-4 focus:outline-none">
              <div className="bg-white rounded-3xl text-white p-3 h-[420px]">
                <img
                  className="mx-auto h-36"
                  src={card.imageUrl}
                  alt={card.title}
                />
                <div className="text-center mt-4">
                  <h2 className="text-lg font-bold text-black mb-2">
                    {card.title}
                  </h2>
                  <p className="text-black">{card.content}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Services;
